<template>
  <div>
    <b-modal id="buyplan" ref="buyplan" centered title="Upgrade to Gold Plan" hide-footer>
      <b-row>
        <b-form-group class="col-sm-6 text-center">
          <i class="ri-money-euro-box-fill" style="font-size: 60px;color: blueviolet;"></i>
        </b-form-group>
        <b-form-group class="col-sm-6">
          - unlimited form rules <br>
          - unlimited forms & fields <br>
          - 20.000 submissions per month <br>
          - 500 MB storage for collecting uploads <br>
        </b-form-group>

        <b-form-group class="col-sm-12">
          <b-button variant="warning" block href="/app/buyplan">Upgrade Now</b-button>
        </b-form-group>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'BuyPlan'
}
</script>
