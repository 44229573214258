<template>
  <b-container fluid style="">
    <b-form style="background: #fff; padding:20px">
      <b-row style="background:white; margin-bottom: 50px; margin-top: 20px">
        <div class="col-sm-6" >
          <h4>{{ $t('payment.payment') }}</h4><br>
          <b-row>
            <b-form-group class="col-sm-4"
                          label-for="selectcurrency"
                          :label="$t('payment.currency')">
              <b-form-select v-model="currency" :options="currencies" id="selectcurrency" @change="addPaymentCurrency">
                <template v-slot:first>
                  <b-form-select-option :value="null">{{ $t('payment.select_currency') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group class="col-sm-8"></b-form-group>

            <b-form-group class="col-sm-4" :label="$t('payment.accept_cash')">
              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                <div class="custom-switch-inner">
                  <input type="checkbox" class="custom-control-input bg-primary" id="cashpayment" v-model="cash" @change="addPaymentCash">
                  <label class="custom-control-label" for="cashpayment" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                  </label>
                </div>
              </div>
            </b-form-group>
          </b-row>
        </div>
        <div class="col-sm-6">
          <h4>{{ $t('payment.other_payment_processors') }}</h4><br>
          <b-row>
            <b-button :class="`${paypal_payment.active ? 'otherProcessorsActive' : 'otherProcessors'}`" class="col-sm-3" @click="thePayment('paypal')" v-model="payment.paypal">
              <img  src="../../assets/images/payment/paypal.png" style="height: 50px;">
            </b-button>

            <b-button :class="`${stripe_payment.active ? 'otherProcessorsActive' : 'otherProcessors'}`" class="col-sm-3 otherProcessors" @click="thePayment('stripe')" v-model="payment.stripe">
              <img src="../../assets/images/payment/stripe.png" style="height: 50px;">
            </b-button>

            <b-button :class="`${revolut_payment.active ? 'otherProcessorsActive' : 'otherProcessors'}`" @click="thePayment('revolut')" class="col-sm-3 otherProcessors" v-model="payment.revolut">
              <img src="../../assets/images/payment/revolut.png" style="height: 60px;">
            </b-button>

            <!--
            <b-button :class="`${authorize_payment.active ? 'otherProcessorsActive' : 'otherProcessors'}`" @click="thePayment('authorize')" class="col-sm-3 otherProcessors" v-model="payment.authorize">
              <img src="../../assets/images/payment/authorizenet-675700.png" style="height: 100px;">
            </b-button>
            -->
            <b-form-group class="col-sm-12">
              <br>
            </b-form-group>

            <b-form-group class="col-sm-12" v-show="thePaymentValue === 'paypal'">
              <h4>{{ $t('payment.paypal_settings') }}</h4>
              {{ $t('payment.paypal_client_id') }} <br>
              <b-input name="paypal-address" v-model="paypal_payment.address" style="width: 300px" placeholder="Paypal account id" v-on:change="savePayment('paypal', paypal_payment)"></b-input>
              <br>
              <span v-show="device !== 'ios'">
                {{ $t('payment.paypal_sandbox_id') }} <br>
                <b-input name="paypal-address-test" v-model="paypal_payment.address_test" style="width: 300px" placeholder="Paypal sandbox account id" v-on:change="savePayment('paypal', paypal_payment)"></b-input>
                <br>
              </span>
              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                <div class="custom-switch-inner">
                  <input type="checkbox" class="custom-control-input bg-primary" id="paypal_active" v-model="paypal_payment.active" v-on:change="savePayment('paypal', paypal_payment)">
                  <label class="custom-control-label" for="paypal_active" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                  </label>
                </div>
              </div>
              <label for="paypal_active">{{ $t('payment.activate_paypal') }}</label>
            </b-form-group>

            <b-form-group class="col-sm-12" v-show="thePaymentValue === 'stripe'">
              <h4>{{ $t('payment.stripe_settings') }}</h4>
              <span v-show="device !== 'ios'">
                {{ $t('payment.stripe_test_secret') }} <br>
                <b-input name="stripe-test-secret" v-model="stripe_payment.test_secret" style="width: 300px" placeholder="Test Secret Key" v-on:change="savePayment('stripe', stripe_payment)"></b-input>
                {{ $t('payment.stripe_test_publishable') }} <br>
                <b-input name="stripe-test-publishable" v-model="stripe_payment.test_publishable" style="width: 300px" placeholder="Test Publishable Key" v-on:change="savePayment('stripe', stripe_payment)"></b-input>
              </span>
              {{ $t('payment.stripe_live_secret') }} <br>
              <b-input name="stripe-live-secret" v-model="stripe_payment.live_secret" style="width: 300px" placeholder="Live Secret Key" v-on:change="savePayment('stripe', stripe_payment)"></b-input>
              {{ $t('payment.stripe_live_publishable') }} <br>
              <b-input name="stripe-live-publishable" v-model="stripe_payment.live_publishable" style="width: 300px" placeholder="Live Publishable Key" v-on:change="savePayment('stripe', stripe_payment)"></b-input>
              <br>
              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                <div class="custom-switch-inner">
                  <input type="checkbox" class="custom-control-input bg-primary" id="stripe_active" v-model="stripe_payment.active" v-on:change="savePayment('stripe', stripe_payment)">
                  <label class="custom-control-label" for="stripe_active" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                  </label>
                </div>
              </div>
              <label for="stripe_active">{{ $t('payment.activate_stripe') }}</label>
            </b-form-group>

            <b-form-group class="col-sm-12" v-show="thePaymentValue === 'revolut'">
              <h4>{{ $t('payment.revolut_settings') }}</h4>
              {{ $t('payment.revolut_api_key') }} <br>
              <b-input name="revolut-api" v-model="revolut_payment.api" style="width: 300px" placeholder="Add Revolut Api key" v-on:change="savePayment('revolut', revolut_payment)"></b-input>
              <br>
              <span v-show="device !== 'ios'">
                {{ $t('payment.revolut_sandbox_key') }} <br>
                <b-input name="revolut-api-sandbox" v-model="revolut_payment.api_sandbox" style="width: 300px" placeholder="Add Revolut Api Sandbox key" v-on:change="savePayment('revolut', revolut_payment)"></b-input>
                <br>
              </span>
              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                <div class="custom-switch-inner">
                  <input type="checkbox" class="custom-control-input bg-primary" id="revolut_active" v-model="revolut_payment.active" v-on:change="savePayment('revolut', revolut_payment)">
                  <label class="custom-control-label" for="revolut_active" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                  </label>
                </div>
              </div>
              <label for="revolut_active">{{ $t('payment.activate_revolut') }}</label>
            </b-form-group>

            <!--
            <b-form-group class="col-sm-12" v-show="thePaymentValue === 'authorize'">
              <h4>Authorize Settings</h4>
              <b-input name="authorize-loginid" v-model="authorize_payment.loginid" style="width: 300px" placeholder="Add Authorize.net login ID" v-on:change="savePayment('authorize', authorize_payment)"></b-input><br>
              <b-input name="authorize-transactionkey" v-model="authorize_payment.transactionkey" style="width: 300px" placeholder="Add Authorize.net Transaction Key" v-on:change="savePayment('authorize', authorize_payment)"></b-input>
              <br>
              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                <div class="custom-switch-inner">
                  <input type="checkbox" class="custom-control-input bg-primary" id="authorize_active" v-model="authorize_payment.active" v-on:change="savePayment('authorize', authorize_payment)">
                  <label class="custom-control-label" for="authorize_active" data-on-label="Yes" data-off-label="No">
                  </label>
                </div>
              </div>
              <label for="authorize_active">Activate Authorize</label>
            </b-form-group>
            -->

            <b-form-group class="col-sm-12" v-show="device !== 'ios' && (thePaymentValue === 'paypal' || thePaymentValue === 'stripe' || thePaymentValue === 'revolut' || thePaymentValue === 'authorize')">
              <h5>{{ $t('payment.testing_mode') }}</h5>
              <span>{{ $t('payment.applies_for_all') }}</span><br>
              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                <div class="custom-switch-inner">
                  <input type="checkbox" class="custom-control-input bg-primary" id="test_payment" v-model="test_payment" v-on:change="addPaymentTest">
                  <label class="custom-control-label" for="test_payment" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                  </label>
                </div>
              </div>
              <label for="test_payment">{{ $t('payment.use_sandbox') }}</label>
            </b-form-group>
          </b-row>
        </div>
      </b-row>
      <buy-plan title="payment"></buy-plan>
    </b-form>
  </b-container>
</template>

<script>

import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'
import BuyPlan from '@/views/BuyPlan/BuyPlan'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'Payment',
  components: {
    BuyPlan
  },
  mounted () {
    core.index()
    this.getCurrentPayment()
  },
  data () {
    return {
      device: Capacitor.getPlatform() || 'web',
      buyplan: '',
      apiToken: localStorage.getItem('api_token') || '',
      payment: {
        paypal: '',
        stripe: '',
        revolut: '',
        authorize: ''
      },
      paypal_payment: {
        address: '',
        address_test: '',
        active: ''
      },
      stripe_payment: {
        test_publishable: '',
        test_secret: '',
        live_publishable: '',
        live_secret: '',
        active: ''
      },
      revolut_payment: {
        api: '',
        api_sandbox: '',
        active: ''
      },
      authorize_payment: {
        loginid: '',
        transactionkey: '',
        active: ''
      },
      cash: '',
      test_payment: '',
      thePaymentValue: '',
      currency: 'EUR',
      currencies: [
        'RON',
        'USD',
        'EUR'
      ],
      paypal_address: '',
      paypal_active: '',
      stripe_active: '',
      revolut_active: '',
      authorize_active: ''
    }
  },
  methods: {
    thePayment (typeOfPayment) {
      const payment = {
        payment: typeOfPayment
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_CHECK_COMPANY_PAYMENT, payment)
        .then((response) => {
          if (response.data.status === 'fail') {
            alert(response.data.response)
          } else if (response.data.status === 'success') {
            this.thePaymentValue = typeOfPayment
          } else if (response.data.status === 'upgrade') {
            this.$emit('upgrade_message', response.data.nextPlan)
            this.$bvModal.show('globalUpgrade')
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addPaymentCurrency () {
      const currency = {
        currency: this.currency
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_COMPANY_CURRENCY, currency)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('payment.currency_has_been_set'))
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addPaymentCash () {
      const cash = {
        cash: this.cash
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_COMPANY_CASH, cash)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('payment.cash_has_been_set'))
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addPaymentTest () {
      const testPayment = {
        payment_test: this.test_payment
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_COMPANY_TEST_PAYMENT, testPayment)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('payment.test_has_been_set'))
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCurrentPayment () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_PAYMENT)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.payment.payment_cash === '0') {
              this.cash = false
            } else {
              this.cash = true
            }
            if (response.data.payment.payment_test === '0') {
              this.test_payment = false
            } else {
              this.test_payment = true
            }
            this.currency = response.data.payment.payment_currency
            if (response.data.payment.payment_paypal) {
              const paypalPayment = JSON.parse(response.data.payment.payment_paypal)
              this.paypal_payment.address = paypalPayment.address
              this.paypal_payment.address_test = paypalPayment.address_test
              this.paypal_payment.active = paypalPayment.active
            }
            if (response.data.payment.payment_stripe) {
              const stripePayment = JSON.parse(response.data.payment.payment_stripe)
              this.stripe_payment.test_publishable = stripePayment.test_publishable
              this.stripe_payment.test_secret = stripePayment.test_secret
              this.stripe_payment.live_publishable = stripePayment.live_publishable
              this.stripe_payment.live_secret = stripePayment.live_secret
              this.stripe_payment.active = stripePayment.active
            }
            if (response.data.payment.payment_revolut) {
              const revolutPayment = JSON.parse(response.data.payment.payment_revolut)
              this.revolut_payment.api = revolutPayment.api
              this.revolut_payment.api_sandbox = revolutPayment.api_sandbox
              this.revolut_payment.active = revolutPayment.active
            }
            if (response.data.payment.payment_authorize) {
              const authorizePayment = JSON.parse(response.data.payment.payment_authorize)
              this.authorize_payment.loginid = authorizePayment.loginid
              this.authorize_payment.transactionkey = authorizePayment.transactionkey
              this.authorize_payment.active = authorizePayment.active
            }
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    savePayment (paymentType, paymentData) {
      const formData = {
        paymentType: paymentType,
        paymentData: paymentData
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_COMPANY_PAYMENT, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('payment.payment_updated'))
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>

<style scoped>
.otherProcessors{
  background: #d4d4d4 !important;
  cursor:pointer;
  text-align: center;
  height: 150px;
  padding-top: 30px;
}
.otherProcessorsActive{
  background: #ffcd5f !important;
  cursor:pointer;
  text-align: center;
  height: 150px;
  padding-top: 30px;
}
.otherProcessors:hover,
.otherProcessorsActive:hover{
  background: lightskyblue !important;
}
</style>
